<template>
  <div>
    <TabSelector
      :tabsList="tabsList"
      :selectedTab="selectedTab"
      @tabAction="changeTab"
    >
      <DropdownList
        :selectedDropdown="selectedSort"
        :dropdownItems="dropdownItems"
        @onDropdownAction="onDropdownAction"
      />
    </TabSelector>
    <SportsList @selectSport="selectSport" :selectedSport="selectedSport" />

    <!-- Header -->
    <div v-if="!isMobileDevice" class="flex justify-between mt-s36 text-n-lg font-bold">
      <div class="w-s36 text-text-body">#</div>
      <div class="w-full flex justify-between">
        <div class="w-6/12">{{ $t('market.name') }}</div>
        <div class="w-2/12 pl-s20">{{ $t('market.category') }}</div>
        <div class="w-2/12">{{ $t('market.change_timeframes.change_generic') }}</div>
        <div class="w-2/12">{{ selectedTab === 'assets' ? $t('market.card_value') : $t('market.total_value') }}</div>
      </div>
    </div>

      <!-- Content -->
      <div v-for="(n, index) in data" v-bind:key="index"
        class="flex justify-between items-center text-n-lg font-bold my-n-xl">
        <div v-if="!isMobileDevice" class="w-s36 text-text-body">{{ index + 1 }}</div>
        <MarketListItem
          :data="data[index]"
          :type="selectedTab"
          :index="index"
          :onImageLoad="onImageLoad"
          :selectedTab="selectedTab"
          :isLoading="isLoading"
        />
      </div>
      <div v-if="!globalIsLoading && !data.length" class="my-n-3xl text-text-body">{{ $t('no_data') }}</div>

      <!-- View More Button -->
      <Button
        @buttonAction="$emit('loadMore')"
        v-if="!isLoading && !maxResultsReached && data.length >= 100"
        :isLoading="isLoadingMore"
        :label="$tc('market.view_more')"
        :customClasses="`bg-background-primary text-n-2xl font-bold border border-border cursor-pointer rounded-10 py-n-3xl px-n-xl ${!isMobileDevice ? 'ml-n-5xl' : ''}`"
      />
  </div>
</template>

<script>
import Button from '@/components/misc/Button';
import { MarketListItem } from '@/components/market';
import { TabSelector } from '@/components/misc';
import SportsList from '@/components/market/SportsList';
import { DropdownList } from '@/components/dropdown';

export default {
  name: 'MarketsList',
  components: {
    DropdownList,
    Button,
    MarketListItem,
    TabSelector,
    SportsList,
  },

  data: function () {
    return {
      imagesLoaded: 0,
      tabsList: ['assets','collections'],
      isOpen: false,
      selectedSort: { label: this.$t('filter.price'), action: 'price' },
    };
  },

  props: {
    data: Array,
    isLoading: Boolean,
    isLoadingMore: Boolean,
    maxResultsReached: Boolean,
    selectedTab: String,
    dropdownItems: Array,
    selectSport: Function,
    selectedSport: Number,
  },

  watch: {
    dropdownItems() {
      this.selectedSort = this.dropdownItems[0];
      this.$emit('onDropdownAction', this.dropdownItems[0].action);
    }
  },

  methods: {
    onImageLoad() {
      this.imagesLoaded = this.imagesLoaded + 1;
    },

    changeTab(newTab) {
      this.$emit('changeTab', newTab);
    },

    onDropdownAction(item) {
      this.selectedSort = item;

      this.$emit('onDropdownAction', item.action);
    },

    toggle() {
      this.isOpen = !this.isOpen;
    },

    closeDropdown() {
      this.isOpen = false;
    },
  },
};
</script>
