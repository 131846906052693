<template>
  <div v-if="globalIsLoading" class="sports">
    <SkeletonLoading width="71px" />
  </div>
 
  <div v-else @click="selectSport(data.id)" class="sports">
    <div class="sports-text" :class="sportsClasses">
      {{ data.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SportsListItem',

  props: {
    data: [Object, Number],
    index: Number,
    onImageLoad: Function,
    selectedSport: Number,
  },

  computed: {
    sportsClasses() {
      return {
        'sports-text--active': this.selectedSport === this.data.id,
      };
    }
  },
  
  methods: {
    selectSport(sportId) {
      this.$emit('selectSport', sportId);
    }
  },
};
</script>

<style scoped>
  .sports {
    @apply mr-2-5 cursor-pointer whitespace-no-wrap;
  }
  .sports:last-of-type {
    @apply mr-0;
  }
  .sports-text {
    @apply flex items-center justify-center font-medium relative px-n-lg rounded-24 py-2 cursor-pointer  border border-border  text-text-inactive ;
    height: 36px;
    min-width: 60px;

  }
  .sports-text--active {
    @apply text-text-body-inverted border-black bg-background-inverted;
  }
</style>
