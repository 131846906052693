<template>
  <LayoutMainPages>
    <div class="flex justify-between items-center xl:flex-col">
      <div class="flex justify-between items-center w-full">
        <Title :title="$tc('navigation.markets')"/>
      </div>
    </div>

    <MarketsList
      :data="data || new Array(20).fill(0)"
      :isLoading="isLoading"
      @loadMore="loadMore"
      :isLoadingMore="isLoadingMore"
      :maxResultsReached="maxResultsReached"
      @changeTab="changeTab"
      :selectedTab="selectedTab"
      :dropdownItems="dropdownItems"
      @onDropdownAction="onDropdownAction"
      :selectSport="selectSport"
      :selectedSport="sportId"
      class="mt-s20"
    />
  </LayoutMainPages>
</template>

<script>
import { debounce } from 'vue-debounce';

import { LayoutMainPages, Title } from '@/components/misc';
import MarketsList from '@/components/market/MarketsList';
import MarketQuery from '@/graphql/queries/Market.gql';
import LOAD_MORE_PLAYERS from '@/graphql/queries/LoadMorePlayers.gql';
import LOAD_MORE_PAIRS from '@/graphql/queries/LoadMorePairs.gql';

const TRACKING_EVENT_TYPE = {
  'assets': 'MARKETS_ASSETS_VIEWED',
  'collections': 'MARKETS_PLAYERS_VIEWED',
};

const TAB_NAME_TO_API_NAME = {
  assets: 'cards',
  collections: 'players',
};

const TAB_NAME_SELECTED = {
  assets: 'assets',
  collections: 'collections',
};

export default {
  name: 'Market',
  components: {
    LayoutMainPages,
    Title,
    MarketsList,
  },
  data() {
    const FILTER_OPTIONS = {
      assets: [
        { label: this.$t('filter.price'), action: 'price' },
        { label: this.$t('filter.volume'), action: 'volume' },
        { label: this.$t('filter.top_gainers'), action: 'gain' },
        { label: this.$t('filter.top_losers'), action: 'loss' },
      ],
      collections: [
        { label: this.$t('filter.market_cap'), action: 'marketcap' },
        { label: this.$t('filter.volume'), action: 'volume' },
        { label: this.$t('filter.top_gainers'), action: 'gain' },
        { label: this.$t('filter.top_losers'), action: 'loss' },
      ],
    };

    return {
      data: null,
      FILTER_OPTIONS: FILTER_OPTIONS,
      selectedTab: this.getTypeFromUrl(),
      sportId: null,
      selectedSort: 'price',
      dropdownItems: FILTER_OPTIONS['assets'],
      isLoading: true,
      isLoadingMore: false,
      maxCount: 100,
      maxResultsReached: false,
      isOpen: false,
      debouncer: null,
    };
  },

  computed: {
    formattedPairs() {
      return this.$store.state.api['pairs'].map(item => {
        return {
          collection_name: this.lodashGet(item, 'base.associated_players[0].name', ''),
          id: item.id,
          id_hr: item.id_hr,
          img: item.img,
          name: this.lodashGet(item, 'base.name', ''),
          price_change_24h: item.price_change_24h,
          price_change_24h_pct: item.price_change_24h_pct,
          price_per_token: item.price_per_token,
          price_change: item.price_change,
          long_name: item.base.long_name,
          sport: this.lodashGet(item, 'base.associated_players[0].sport.name', ''),
          quote: item.quote,
          ticker: this.lodashGet(item, 'base.ticker', ''),
        };
      });
    },
    sportsList() {
      return this.$store.state.api['sports'];
    },
  },

  async mounted() {
    this.debouncer = debounce(() => {
      this.filterMarket();
      this.maxResultsReached = false;
    }, 300);

    if (!this.globalIsLoading) {
      // if page is already loaded when mounted, it means this is the initial loading (i.e. page refresh)
      await this.loaded();
    }
  },

  methods: {
    async loaded() {
      await this.$store.dispatch('events/track', {
        event: 'MARKETS_VIEWED',
      });

      const sport = this.sportsList && this.sportsList.find(sport => sport.id_hr === this.$route.params.sport_id_hr);
      await this.selectSport(sport ? sport.id : null, true);
      const type = this.$route.params.type === 'cards' ? 'assets' : this.$route.params.type;
      this.selectedTab = type in this.FILTER_OPTIONS ? type : 'assets';
      await this.filterMarket();
      this.maxResultsReached = false;

      await this.$store.dispatch('events/track', {
        event: TRACKING_EVENT_TYPE[this.selectedTab],
      });
    },

    async filterMarket() {
      try {
        this.isLoading = true;
      
        await this.$store.dispatch('api/refreshPageData', {
          $apollo: this.$apollo,
          query: MarketQuery,
          variables: {
            sport_id: this.sportId,
          },
        });

        await this.changeTab(this.selectedTab, false);
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },

    getTypeFromUrl() {
      return this.$route.params.type === 'collections' ? 'collections': 'assets';
    },

    toggle() {
      this.isOpen = !this.isOpen;
    },

    async refreshData() {
      try {
        await this.$store.dispatch('api/refreshPageData', {
          $apollo: this.$apollo,
          query: MarketQuery,
          variables: {
            player_sort: this.selectedTab === TAB_NAME_SELECTED.collections ? this.selectedSort : undefined,
            pair_sort: this.selectedTab === TAB_NAME_SELECTED.assets ? this.selectedSort : undefined,
            sport_id: this.sportId,
          },
        });
      } catch (err) {
        await this.showError(err);
      }
    },

    async onDropdownAction(action) {
      if (this.selectedSort === action) {
        return;
      }
      this.selectedSort = action || this.selectedSort;
      await this.changeTab(this.selectedTab);
    },

    async changeTab(newTab, refreshData = true) {
      try {
        this.isLoading = true;

        if (this.selectedTab !== newTab) {
          this.maxResultsReached = false;
          this.dropdownItems = this.FILTER_OPTIONS[newTab];
          this.selectedSort = this.FILTER_OPTIONS[newTab][0].action;
          this.selectedTab = newTab;
          await this.$store.dispatch('events/track', {
            event: TRACKING_EVENT_TYPE[this.selectedTab],
          });
        }

        if (refreshData) {
          await this.refreshData();
        }

        if (this.selectedTab === 'assets') {
          this.data = this.formattedPairs || new Array(20).fill(0);
        } else {
          this.data = this.$store.state.api[TAB_NAME_TO_API_NAME.collections] || new Array(20).fill(0);
        }

        window.history.replaceState({}, null, this.sportName ? `/market/${this.sportName}/${newTab}` : `/market/${newTab}`);
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },

    async loadMore() {
      this.isLoadingMore = true;

      try {
        if (this.selectedTab === 'collections') {
          const response = await this.$apollo.query({
            query: LOAD_MORE_PLAYERS,
            variables: {
              sort: this.selectedSort,
              sport_id: this.sportId,
              count: this.maxCount,
              offset: this.data.length > 100 ? this.data.length : 100,
            },
          });

          if (!response.data[TAB_NAME_TO_API_NAME.collections].length) {
            this.maxResultsReached = true;
            await this.showError(new Error('no_more_items'));

            return;
          }
          
          this.$store.commit('api/setState', {
            stateName: TAB_NAME_TO_API_NAME.collections, 
            stateData: [...this.data, ...response.data[TAB_NAME_TO_API_NAME.collections]]
          });
        } else {
          const response = await this.$apollo.query({
            query: LOAD_MORE_PAIRS,
            variables: {
              sort: this.selectedSort,
              count: this.maxCount,
              offset: this.data.length > 100 ? this.data.length : 100,
            },
          });

          if (!response.data.pairs.length) {
            this.maxResultsReached = true;
            await this.showError(new Error('no_more_items'));

            return;
          }
          
          this.$store.commit('api/setState', { stateName: 'pairs', stateData: [...this.$store.state.api['pairs'], ...response.data.pairs] });
        }
      } catch (err) {
        await this.showError(err);
      } finally {
        await this.changeTab(this.selectedTab, false);
        this.isLoadingMore = false;
      }
    },

    closeDropdown() {
      this.isOpen = false;
    },

    async selectSport(sportId, firstCall) {
      this.sportId = this.sportId !== sportId ? sportId : null;

      const sport = this.sportsList && this.sportsList.find(sport => sport.id === this.sportId);

      this.sportName = sport ? sport.id_hr : null;

      if (!firstCall) {
        await this.onDropdownAction();
      }
    }
  },

  watch: {
    globalIsLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  }
};
</script>
