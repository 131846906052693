<template>
  <div class="sports-container mt-6 xl:mt-3" v-dragscroll>
    <div @click="selectSport(null)" class="sports-mobile-text" :class="selectedSportClasses">ALL</div>
    <SportsListItem v-for="(n, index) in data" v-bind:key="index"
      :data="data[index]"
      @selectSport="selectSport"
      :selectedSport="selectedSport"
    />
  </div>
</template>

<script>
import SportsListItem from '@/components/market/SportsListItem.vue';

export default {
  name: 'SportsList',
  components: {
    SportsListItem,
  },

  computed: {
    data() {
      return this.$store.state.api['sports'] || new Array(5).fill(0);
    },

    selectedSportClasses() {
      return {
        'sports-mobile-text--active': !this.selectedSport,  
      };
    },
  },

  props: {
    selectedSport: Number,
  },

  methods: {
    selectSport(sportId) {
      this.$emit('selectSport', sportId);
    }
  },
};
</script>

<style scoped>
  .sports-container {
    @apply flex overflow-x-auto py-1;
    max-width: calc(100vw - 40px);
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .sports-container::-webkit-scrollbar {
    display: none;
  }
  .sports-mobile-text {
    @apply flex items-center justify-center font-medium relative px-n-lg py-s8 mr-s12 cursor-pointer rounded-24 border border-border text-text-inactive;
    height: 36px;
    min-width: 60px;
  }

  .sports-mobile-text--active {
    @apply text-text-body-inverted border-black bg-background-inverted;
  }
</style>